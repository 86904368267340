import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
// import AuthLayout from "layouts/auth";
// import AdminLayout from "layouts/admin";
import OfficerLayout from "layouts/officer";
import AdminLayout from "layouts/admin";
import UserLayout from "layouts/user";
import ErrorLayout from "layouts/dashboard";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import ContextController from "context/contextController";

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>

      <ThemeEditorProvider>
        <HashRouter>
          <ContextController>
            <Switch>
              <Route path={`/admin`} component={AdminLayout} />
              <Route path={`/user`} component={UserLayout} />
              <Route path={`/officer`} component={OfficerLayout} />
              <Route path={`/error`} component={ErrorLayout} />
              <Redirect exact from='/' to='/admin' />
              {/* <Redirect from='*' to='/error' /> */}
            </Switch>
          </ContextController> 
        </HashRouter>
      </ThemeEditorProvider>

    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
