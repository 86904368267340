import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdHome
} from "react-icons/md";

// Admin Imports
import Admin from "views/admin/dashboard";
import AdminStats from "views/admin/stats";
import User from "views/user/dashboard";
import Officer from "views/officer/dashboard";


const routes = [

  {
    name: "Grievances",
    layout: "/user",
    path: "/dashboard",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: User,
  },
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/dashboard",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Admin,
  },
  {
    name: "Admin Stats",
    layout: "/admin",
    path: "/stats",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: AdminStats,
  },
  {
    name: "Officer",
    layout: "/officer",
    path: "/dashboard",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Officer,
  },

];

export default routes;
