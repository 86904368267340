import { isEmpty } from 'lodash'
import { AppContext } from "../../../../context"
import React from "react"
import MiniCalendar from "../../../../components/calendar/MiniCalendar"
import { format } from 'date-fns'

const { Button, ModalOverlay, Modal, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Text, Flex, Center } = require("@chakra-ui/react")

export default function GrievanceModal() {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = React.useRef()
    // const context = useContext(AppContext)

    return (
        <AppContext.Consumer>
            {context => (<>
                <Button variant='brand' onClick={onOpen}>
                    {!isEmpty(context.dateValue)
                        ?
                        <Text
                            fontSize={{ sm: "sm", lg: "xs", xl: "xs" }}>
                            <b>
                                {format(new Date(context.dateValue[0]), "yyyy-MM-dd")} - {format(new Date(context.dateValue[1]), "yyyy-MM-dd")}

                            </b>
                        </Text>
                        : <Text fontSize={{ sm: "sm", lg: "lg", xl: "xl" }}>Select Date</Text>}
                </Button>
                <Flex>

                    <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>
                                <Center >
                                    <Text fontSize={{ sm: "sm", lg: "lg", xl: "xl" }}>
                                        Select Date
                                    </Text>
                                </Center>
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <MiniCalendar h='100%' minW='100%' selectRange={true} dateValue={context.dateValue} dateOnChange={context.dateOnChange} />
                            </ModalBody>
                            <ModalFooter>
                                <Center>
                                    <Button colorScheme="brand" mr={3} onClick={() => {
                                        context.getGrieviencesTableData()
                                        onClose()
                                    }}>
                                        Submit
                                    </Button>
                                </Center>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Flex>
            </>)
            }
        </AppContext.Consumer>
    )
}