import { AppContext } from "../../../../context"
import { isEmpty } from "lodash"
import React from "react"
import MiniCalendar from "../../../../components/calendar/MiniCalendar"
import { format } from "date-fns"
import { AttachmentIcon } from '@chakra-ui/icons'
const { Button, ModalOverlay, Modal, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Text, Flex, Center, Image } = require("@chakra-ui/react")

export default function EvidanceViewer({imageUrl}) {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = React.useRef()


    return (
        <AppContext.Consumer>
            {context => (<>
                <Button variant='brand' onClick={onOpen}>
                   <AttachmentIcon boxSize={6}/>
                </Button>
                <Flex>

                    <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>
                                <Center >
                                    <Text fontSize={{ sm: "sm", lg: "lg", xl: "xl" }}>
                                      Evidance Image
                                    </Text>
                                </Center>
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Image src={imageUrl}/>
                            </ModalBody>
                            <ModalFooter>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Flex>
            </>)
            }
        </AppContext.Consumer >
    )
}