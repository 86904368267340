import React from 'react'

// Chakra imports
import {
    Button,
    Menu,
    MenuButton,
    MenuItemOption,
    MenuList,
    Text,

} from "@chakra-ui/react";

// Context components
import { AppContext } from "../../context";
import { FILTER_TYPE, RECORDS_PER_PAGE_DROPDOWN_DATA } from '../../constants';


export const FilterDropdowns = () => {


    return (
        <AppContext.Consumer>
            {context => (<>
                <Menu closeOnSelect={true} >
                    <MenuButton as={Button} colorScheme="brand" >
                        <Text fontSize={{ sm: "sm", lg: "lg", xl: "xl" }}>
                            {
                                context.statusFilter
                                    ?
                                    <Text fontSize={{ sm: "sm", lg: "sm", xl: "sm" }}>{context.statusFilter}</Text>
                                    :
                                    <Text fontSize={{ sm: "sm", lg: "lg", xl: "xl" }}>Select status</Text>
                            }
                        </Text>
                    </MenuButton>
                    <MenuList fontSize={{ sm: "sm", lg: "lg", xl: "xl" }}>
                        <MenuItemOption onClick={() => context.changeTypeOrStatusFilter("", FILTER_TYPE.STATUS)}>All</MenuItemOption>

                        {
                            context.complaintStatus.map(status => {
                                return (
                                    <MenuItemOption key={status.value} onClick={() => context.changeTypeOrStatusFilter(status.value, FILTER_TYPE.STATUS)}>{status.displayName}</MenuItemOption>
                                )
                            })
                        }
                    </MenuList>
                </Menu>
                <Menu closeOnSelect={true} >
                    <MenuButton as={Button} colorScheme="brand" >

                        {
                            context.typeFilter
                                ?
                                <Text fontSize={{ sm: "sm", lg: "sm", xl: "sm" }}>{context.typeFilter}</Text>
                                :
                                <Text fontSize={{ sm: "sm", lg: "lg", xl: "xl" }}>Select Type</Text>
                        }

                    </MenuButton>
                    <MenuList fontSize={{ sm: "sm", lg: "lg", xl: "xl" }}>
                        <MenuItemOption onClick={() => context.changeTypeOrStatusFilter("", FILTER_TYPE.TYPE)}>All</MenuItemOption>

                        {
                            context.grieviencesDropdownData.map(status => {
                                return (
                                    <MenuItemOption key={status.value} onClick={(e) => context.changeTypeOrStatusFilter(status.value, FILTER_TYPE.TYPE)}>{status.displayName}</MenuItemOption>
                                )
                            })
                        }

                    </MenuList>
                </Menu>
                <Menu closeOnSelect={true} >
                    <MenuButton as={Button} colorScheme="brand" >
                        <Text fontSize={{ sm: "sm", lg: "sm", xl: "sm" }}>
                            Records per page: {context.numberOfRecords}
                        </Text>
                    </MenuButton>
                    <MenuList fontSize={{ sm: "sm", lg: "lg", xl: "xl" }}>
                        {
                            RECORDS_PER_PAGE_DROPDOWN_DATA.map(status => {
                                return (
                                    <MenuItemOption key={status.value} onClick={() => context.setnumberOfRecords(status.value)}>{status.label}</MenuItemOption>
                                )
                            })
                        }

                    </MenuList>
                </Menu>
            </>)
            }
        </AppContext.Consumer >
    )
}
