import { AppContext } from "../../../../context"
import { isEmpty } from "lodash"
import React, { useState } from "react"
import {CSVLink} from 'react-csv';
import MiniCalendar from "../../../../components/calendar/MiniCalendar"
import { format } from "date-fns"
import { jsPDF } from "jspdf"
import autoTable from 'jspdf-autotable'

const { Button, ModalOverlay, Menu, MenuButton, MenuList,MenuItemOption, Modal, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Text, Flex, Center } = require("@chakra-ui/react")

export default function Download() {
        const headers = [
        // {label:"Sr. No.", key:'wardNo'},
         {label:"GRIEVANCE NO", key: 'grievanceNumber' }, 
         {label:"PROPERTY ID", key: 'ddn'}, 
         {label:"TYPE",key: 'type',},  
         {label:"OWNER NAME", key: 'survey.propertyOwnerName'}, 
         {label:"MOBILE NUMBER", key: 'survey.contactNumber'}, 
         {label:"WARD NO", key: 'survey.wardNumber'},
         {label:"LONGITUDE", key: 'survey.long'}, 
         {label:"LATTITUDE",key: 'survey.lat',},  
         {label:"STATUS", key: 'status'}, 
         {label:"COMMENTS", key: 'otherComment'}, 
         {label:"EVIDANCE IMAGE", key: 'evidanceImage'},
         {label:"REPORTED AT", key: 'createdAt'}, 
         {label:"LAST ACTIVITY AT", key: 'updatedAt'},
        
      ];

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [downloadFormat, setFormat] = useState("Download")
    const finalRef = React.useRef()
      // PDF 

    const  makePdf = (context) => {
        setFormat("PDF");
        const doc = new jsPDF({
            orientation: "landscape",
            // unit: "in",
            // format: [4, 2]
        });
        // console.log(context?.greviencesDataAdmin)
        const bodyData = context?.greviencesDataAdmin.map((item)=>{
            return {
                grievanceNumber: item.grievanceNumber,
                ddn: item?.ddn,
                type: item?.type,
                propertyOwnerName: item?.survey?.propertyOwnerName,
                contactNumber: item?.survey?.contactNumber,
                wardNumber: item?.survey?.wardNumber,
                status: item?.status,
                otherComment: item?.otherComment
            }
        })
        // console.log("bodyData", bodyData)
        autoTable(doc, {
            // head: [headers.map((item)=>{
            //     return item.label
            // })],
            
            body:  bodyData,
            columns : [
                // {header:"Sr. No.", dataKey:'wardNo'},
                 {header:"GRIEVANCE NO", dataKey: 'grievanceNumber' }, 
                 {header:"PROPERTY ID", dataKey: 'ddn'}, 
                 {header:"TYPE",dataKey: 'type',},  
                 {header:"OWNER NAME", dataKey: 'propertyOwnerName'}, 
                 {header:"MOBILE NUMBER", dataKey: 'contactNumber'}, 
                 {header:"WARD NO", dataKey: 'wardNumber'},
                //  {header:"LONGITUDE", dataKey: 'long'}, 
                //  {header:"LATTITUDE",dataKey: 'lat',},  
                 {header:"STATUS", dataKey: 'status'}, 
                 {header:"COMMENTS", dataKey: 'otherComment'}, 
                //  {header:"EVIDANCE IMAGE", dataKey: 'evidanceImage'},
                //  {header:"REPORTED AT", dataKey: 'createdAt'}, 
                //  {header:"LAST ACTIVITY AT", dataKey: 'updatedAt'},
                
              ],
           
          })
        doc.save(`Grivence_Report_Ponda_${format(new Date(context.dateValue[0]), "yyyy-MM-dd")} - ${format(new Date(context.dateValue[1]), "yyyy-MM-dd")}.pdf`);
      }
    return (
        <AppContext.Consumer>
            {context => (<>
                <Menu closeOnSelect={true} >
                    <MenuButton as={Button} colorScheme="brand" >

                        {
                            downloadFormat !== "Download"
                                ?
                                <Text fontSize={{ sm: "sm", lg: "sm", xl: "sm" }}>{downloadFormat}</Text>
                                :
                                <Text fontSize={{ sm: "sm", lg: "lg", xl: "xl" }}>Download</Text>
                        }

                    </MenuButton>
                    <MenuList fontSize={{ sm: "sm", lg: "lg", xl: "xl" }}>
                        <MenuItemOption onClick={() => setFormat("CSV")}>
                            <CSVLink data={context?.greviencesDataAdmin} filename={`Grivence_Report_Ponda_${format(new Date(context.dateValue[0]), "yyyy-MM-dd")} - ${format(new Date(context.dateValue[1]), "yyyy-MM-dd")}`} headers={headers}>
                             <Text fontSize={{ sm: "sm", lg: "lg", xl: "xl" }}>CSV</Text>
                            </CSVLink>
                        
                        </MenuItemOption>
                        <MenuItemOption onClick={() => makePdf(context)}>
                            
                        
                        <Text fontSize={{ sm: "sm", lg: "lg", xl: "xl" }}>PDF</Text>
                        </MenuItemOption>
                       
                    </MenuList>
                </Menu>
            </>)
            }
        </AppContext.Consumer >
    )
}