
import { Box, SimpleGrid } from "@chakra-ui/react";
import ColumnsTable from "views/user/dashboard/components/ColumnsTable";

import GrievanceModal from "views/user/dashboard/components/GrievanceModal";
import React from "react";
import { FilterDropdowns } from "components/filtersDropdowns/FilterDropdowns";
import SelectDateModal from "./components/SelectDateModal";


export default function Settings() {


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      <SimpleGrid
        columns={{ base: 1, md: 3, "2xl": 6, }}
        gap='20px'
        mb='20px'>
        <GrievanceModal />
      </SimpleGrid>

      <SimpleGrid
        columns={{ base: 1, md: 3, "2xl": 6, }}
        gap='20px'
        mb='20px'>
        <FilterDropdowns />
        <SelectDateModal />
      </SimpleGrid>
      <ColumnsTable />
    </Box >
  );
}
