import { AppContext } from "../../../../context"
import React, { useContext, useEffect, useState } from "react"

const { Box, Button, ModalOverlay, Modal, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, FormControl, Input, FormHelperText, FormLabel, Textarea, MenuButton, Menu, MenuList, MenuOptionGroup, MenuItemOption, Text, Flex } = require("@chakra-ui/react")

export default function GrievanceModal() {
    const [number, setnumber] = useState("")

    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = React.useRef()


    return (
        <AppContext.Consumer>
            {context => (<>
                <Button variant='brand' onClick={onOpen}>
                    <Text fontSize={{ sm: "sm", lg: "lg", xl: "xl" }}>
                        Add Grievance
                    </Text>

                </Button>
                <Flex>


                    <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Add Grievance</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <FormControl id="Grievance">
                                    <FormLabel>Select Grievance</FormLabel>
                                    <Menu closeOnSelect={true} >
                                        <MenuButton as={Button} colorScheme="brand" value={context.grievienceValue.displayName}>
                                            <Text fontSize={{ sm: "sm", lg: "sm", xl: "xs" }}>
                                                {context.grievienceValue.displayName}
                                            </Text>
                                        </MenuButton>
                                        <MenuList >

                                            {context.grieviencesDropdownData.map((data) => <MenuItemOption onClick={() => {
                                                context.setGrievienceValue({ displayName: data?.displayName, type: data?.value })
                                                context.setGrievience(data?.value)

                                            }} value={data?.value}>{data?.displayName}</MenuItemOption>)
                                            }
                                        </MenuList>
                                    </Menu>
                                </FormControl>
                                <FormControl id="otherGrievance">
                                    <FormLabel>Other Grievances</FormLabel>
                                    <Textarea value={context.otherComment} onChange={(e) => context.setotherComment(e.target.value)} type="otherComment" />
                                </FormControl>
                                <FormControl id="file">
                                    <FormLabel>Evidance Image</FormLabel>
                                    <Input type="file" accept={'image/*'} onChange={(e) => context.setEvidanceImg( e.target.files[0])} />
                                    <FormHelperText>Please Upload any relevant image. File format must be .jpg or .jpeg</FormHelperText>
                                </FormControl>
                                <FormControl id="email">
                                    <FormLabel>Mobile Number</FormLabel>
                                    <Input type="contactNumber" value={context.contactNumber} onChange={(e) => context.setMobileNumber(e.target.value)} />
                                    <FormHelperText>Please enter your registered contact number staring with {context.dummycontactNumber}</FormHelperText>
                                </FormControl>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="brand" mr={3} onClick={() => {
                                    context.addGrieviences()
                                    context.getGrieviencesTableData()
                                }}>
                                    Submit
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Flex>
            </>)
            }
        </AppContext.Consumer >
    )
}