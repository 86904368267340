import {
  Button,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Link,
  Box
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { Pagination } from "@material-ui/lab";
// Custom components
import Card from "components/card/Card";
import { AppContext } from "../../../../context";
import { format } from "date-fns";
import EvidanceViewer from "./EvidanceViewer"

const colorArr = [
  {
    "value": "OPEN",
    "color": "blue"
  },
  {
    "value": "IN_PROGRESS",
    "color": "orange"
  },
  {
    "value": "RESOLVED",
    "color": "green"
  },
  {
    "value": "FAILED_TO_RESOLVE",
    "color": "red"
  }
]

export default function ColumnsTable(props) {

  const context = useContext(AppContext)

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const handleChange = (e, p) => {
    context.setpageNumber(p)
  };

  return (
    <AppContext.Consumer>
      {context => {
        // console.log("context ====>" ,  context?.greviencesDataAdmin )
        const filteredData = context?.greviencesDataAdmin.filter(item =>{
          // console.log("item", item)
          let lowerCaseString = context?.searchString.toLowerCase().trim();
          if(item?.survey){
            
            return Object.keys(item?.survey).some(key =>{
               
                return item?.survey[key].toString().toLowerCase().includes(lowerCaseString)
            })
          }
      });
      // console.log("filteredData", filteredData)

        return (
          <Box>
            <Card
              direction='column'
              w='100%'
              px='0px'
              h="60vh"
              overflowX={{ sm: "scroll", lg: "scroll" }}>
              <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text
                  color={textColor}
                  fontSize='22px'
                  fontWeight='500'
                  lineHeight='100%'>
                </Text>
              </Flex>
              <Table variant='simple' color='gray.500' mb='24px' >

                <Thead>
                  <Tr >
                    <Th
                      pe='5px'
                      borderColor={borderColor}>
                      <Flex
                        justify='space-between'
                        align='center'
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color='gray.400'>
                        Sr No.
                      </Flex>
                    </Th>
                    <Th
                      pe='5px'
                      borderColor={borderColor}>
                      <Flex
                        justify='space-between'
                        align='center'
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color='gray.400'>
                        Grievance No
                      </Flex>
                    </Th>
                    <Th
                      pe='5px'
                      borderColor={borderColor}>
                      <Flex
                        justify='space-between'
                        align='center'
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color='gray.400'>
                        Property Id
                      </Flex>
                    </Th>
                    <Th
                      pe='5px'
                      borderColor={borderColor}>
                      <Flex
                        justify='space-between'
                        align='center'
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color='gray.400'>
                        Type
                      </Flex>
                    </Th>
                    <Th
                      pe='5px'
                      borderColor={borderColor}>
                      <Flex
                        justify='space-between'
                        align='center'
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color='gray.400'>
                        Owner Name
                      </Flex>
                    </Th>
                    <Th
                      pe='5px'
                      borderColor={borderColor}>
                      <Flex
                        justify='space-between'
                        align='center'
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color='gray.400'>
                        Mobile Number
                      </Flex>
                    </Th>
                    <Th
                      pe='5px'
                      borderColor={borderColor}>
                      <Flex
                        justify='space-between'
                        align='center'
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color='gray.400'>
                        Ward No
                      </Flex>
                    </Th>
                    <Th
                      pe='5px'
                      borderColor={borderColor}>
                      <Flex
                        justify='space-between'
                        align='center'
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color='gray.400'>
                        Longitude, Lattitude
                      </Flex>
                    </Th>
                    <Th
                      pe='5px'
                      borderColor={borderColor}>
                      <Flex
                        justify='space-between'
                        align='center'
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color='gray.400'>
                        Status
                      </Flex>
                    </Th>
                    <Th
                      pe='100px'
                      
                      borderColor={borderColor}>
                      <Flex
                        justify='space-between'
                        align='center'
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color='gray.400'>
                        Comments
                      </Flex>
                    </Th>
                    <Th
                      pe='5px'
                      borderColor={borderColor}>
                      <Flex
                        justify='space-between'
                        align='center'
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color='gray.400'>
                        Evidance Image
                      </Flex>
                    </Th>
                    <Th
                      pe='5px'
                      borderColor={borderColor}>
                      <Flex
                        justify='space-between'
                        align='center'
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color='gray.400'>
                        Reported At
                      </Flex>
                    </Th>
                    <Th
                      pe='5px'
                      borderColor={borderColor}>
                      <Flex
                        justify='space-between'
                        align='center'
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color='gray.400'>
                        Last Activity At
                      </Flex>
                    </Th>
                    
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredData ? filteredData.map((dt, index) => (
                    <Tr key={index}>
                      <Td
                        fontSize={{ sm: "9px !important" }}
                        pe='0px' borderColor='transparent'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {context.numberOfRecords * (context.pageNumber - 1) + index + 1}
                        </Text>
                      </Td>
                      <Td
                        fontSize={{ sm: "9px !important" }}
                        pe='0px'
                        minW={{ sm: "auto", md: "auto", lg: "auto" }}
                        borderColor='transparent'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {dt?.grievanceNumber}
                        </Text>
                      </Td>
                      <Td
                        fontSize={{ sm: "9px !important" }}
                        pe='0px'
                        minW={{ sm: "auto", md: "auto", lg: "auto" }}
                        borderColor='transparent'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {dt.ddn}
                        </Text>
                      </Td>
                      <Td
                        fontSize={{ sm: "9px !important" }}
                        pe='0px'
                        minW={{ sm: "auto", md: "auto", lg: "auto" }}
                        borderColor='transparent'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {dt.type}
                        </Text>
                      </Td>
                      <Td
                        fontSize={{ sm: "9px !important" }}
                        pe='0px'
                        minW={{ sm: "auto", md: "auto", lg: "auto" }}
                        borderColor='transparent'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {dt?.survey?.propertyOwnerName?.toUpperCase()}
                        </Text>
                      </Td>
                      <Td
                        fontSize={{ sm: "9px !important" }}
                        pe='0px'
                        minW={{ sm: "auto", md: "auto", lg: "auto" }}
                        borderColor='transparent'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {dt.survey.contactNumber}
                        </Text>
                      </Td>
                      <Td
                        fontSize={{ sm: "9px !important" }}
                        pe='0px'
                        minW={{ sm: "auto", md: "auto", lg: "auto" }}
                        borderColor='transparent'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {dt.survey.wardNumber}
                        </Text>
                      </Td>
                      <Td
                        fontSize={{ sm: "9px !important" }}
                        pe='0px'
                        minW={{ sm: "auto", md: "auto", lg: "auto" }}
                        borderColor='transparent'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {dt.survey.lat}, {dt.survey.long}
                        </Text>
                      </Td>
                      <Td
                        fontSize={{ sm: "9px !important" }}
                        pe='0px'
                        minW={{ sm: "auto", md: "auto", lg: "auto" }}
                        borderColor='transparent'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>

                          <Menu closeOnSelect={true} >
                            <MenuButton as={Button} colorScheme={colorArr.filter((option) => option.value === dt.status)[0].color} width="155px">
                              <Text fontSize={{ sm: "sm", lg: "sm", xl: "xs" }}>
                                {dt.status}
                              </Text>
                            </MenuButton>
                            <MenuList maxWidth="140px">
                              {
                                context.complaintStatus.map(status => {
                                  return (
                                    <MenuItemOption key={status.value} onClick={() => {
                                      dt.status !== status.value && context.updateStatus(dt._id, status.value, dt.status)
                                    }}>{status.displayName}
                                    </MenuItemOption>
                                  )
                                })
                              }
                            </MenuList>
                          </Menu>
                        </Text>
                      </Td>
                      <Td
                        fontSize={{ sm: "9px !important" }}
                        align='center'
                        minW={{ sm: "auto", md: "auto", lg: "auto" }}
                        borderColor='transparent'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {dt.otherComment}
                        </Text>
                      </Td>
                      <Td
                        fontSize={{ sm: "9px !important" }}
                        pe='0px'
                        minW={{ sm: "auto", md: "auto", lg: "auto" }}
                        borderColor='transparent'>
                            { dt.evidanceImage ? <EvidanceViewer imageUrl={dt.evidanceImage} />: <Text color={textColor} fontSize='sm' fontWeight='700'>NA</Text>}
                      
                      </Td>
                      <Td
                        fontSize={{ sm: "12px !important" }}
                        pe='0px'
                        minW={{ sm: "auto", md: "auto", lg: "auto" }}
                        borderColor='transparent'>
                        <Text color={textColor} fontSize='sm' fontWeight='700' style={{ fontSize: "4px !important" }}>
                          {format(new Date(dt.createdAt), "E dd MMM yyyy hh:mm:ss").toString()}
                        </Text>
                      </Td>
                      <Td
                        fontSize={{ sm: "12px !important" }}
                        pe='0px'
                        minW={{ sm: "auto", md: "auto", lg: "auto" }}
                        borderColor='transparent'>
                        <Text color={textColor} fontSize='sm' fontWeight='700' style={{ fontSize: "4px !important" }}>
                          {format(new Date(dt.updatedAt), "E dd MMM yyyy hh:mm:ss").toString()}
                        </Text>
                      </Td>
                    </Tr>
                  )) : "No Data found"}
                </Tbody>
              </Table>



            </Card>
              <Flex color="white" >
                <Spacer />
                <Center>
                  <Pagination
                    count={Math.ceil(context.totalAdminData / context.numberOfRecords)}
                    size="large"
                    page={context.pageNumber}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChange}
                  />
                </Center>
                <Spacer />
              </Flex>
          </Box>
        )
      }
      }

    </AppContext.Consumer >
  );
}
