export const FILTER_TYPE = Object.freeze({
    STATUS: 1,
    TYPE: 2
})

export const RECORDS_PER_PAGE_DROPDOWN_DATA = Object.freeze([{
    label: "10",
    value: 10
}, {
    label: "20",
    value: 20
}, {
    label: "30",
    value: 30
}, {
    label: "40",
    value: 40
}, {
    label: "50",
    value: 50
}])