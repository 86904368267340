import { createContext } from 'react';

const initContextState = {
    greviencesData: [],
    grieviencesDropdownData: [],
    getGrieviencesTableData: () => { },
    getGrieviencesDropdownData: () => { },
    addGrieviences: () => { },
    updateStatus: () => { },
    changeTypeOrStatusFilter: () => { },
    setSearchText: () =>{ }
}

export const AppContext = createContext(initContextState);;
