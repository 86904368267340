import { AppContext } from "../../../../context";
import { isEmpty } from "lodash";
import React from "react";
import MiniCalendar from "../../../../components/calendar/MiniCalendar";
import { format } from "date-fns";
import { PhoneIcon, Search2Icon, SearchIcon } from "@chakra-ui/icons";

const { Input, Box, InputGroup, InputLeftElement } = require("@chakra-ui/react");

export default function SearchModel() {
  const finalRef = React.useRef();

  return (
    <AppContext.Consumer>
        {context => (<>
        
        <Box border={"1px"} alignItems={"start"} color="white">
        <InputGroup>
            <InputLeftElement pointerEvents='none'>
            <Search2Icon color='gray.300' />
            </InputLeftElement>
            <Input type='text' placeholder='Search' fontSize={{ sm: "sm", lg: "lg", xl: "xl" }} onChange={(e)=>{
                context.updateSearchText(e.target.value)
            }}/>
         </InputGroup>
        </Box>
        
        </>)
            }
    </AppContext.Consumer >
  );
}
