
// Chakra imports
import {
  Box,
  SimpleGrid
} from "@chakra-ui/react";
import React from "react";
import ColumnsTable from "./components/ColumnsTable";
import SelectDateModal from "./components/SelectDateModal";
import SearchModel from "./components/SearchModel";


// Custom components
import { FilterDropdowns } from "components/filtersDropdowns/FilterDropdowns";
import Download from "./components/Download";

export default function UserReports() {


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      <SimpleGrid
        columns={{ base: 1, md: 3, "2xl": 6, }}
        gap='20px'
        mb='20px'>
        <FilterDropdowns />
        <SearchModel />
        <Download />
        <SelectDateModal />
      </SimpleGrid>
      <ColumnsTable />
    </Box >
  );
}
