
// Chakra imports
import {
  Box, SimpleGrid,

} from "@chakra-ui/react";
import React from "react";
import { StaticsticsCard } from "./components/StaticsticsCard";
import SelectDateModal from "./components/SelectDateModal";
export default function UserReports() {


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 3, "2xl": 6, }}
        gap='20px'
        mb='20px'>
        <SelectDateModal />
      </SimpleGrid>
      <StaticsticsCard />
    </Box >
  );
}
