// Chakra imports
import { Portal, Box, useDisclosure, Container, Text, useColorModeValue } from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin.js";
// Layout components
import Navbar from "components/navbar/NavbarDashboard";
import Sidebar from "components/sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import routes from "routes.js";

// Custom Chakra theme
export default function Error(props) {
  const textColor = useColorModeValue("navy.700", "white");
  const location = useLocation();

  console.log("location.state",)

  return (
    <Box>
      <Container>
        <Text
          mb='5px'
          fontWeight='bold'
          color={textColor}
          fontSize="6xl">
          {location.state.error}
        </Text>
      </Container>
    </Box >
  );
}
